/*
 * Welcome to your app's page JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)

const $ = require('jquery');
global.$ = global.jQuery = $;

import 'bootstrap';
import './styles/frontend/app.scss';

import '@fortawesome/fontawesome-free/js/all';


// start the Stimulus application
import './bootstrap';

